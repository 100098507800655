import React, { useEffect } from 'react'
import TagManager from 'react-gtm-module'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import PrivateRoute from './components/common/auth/PrivateRoute'
import PageWithTitle from './components/common/page/PageWithTitle'
import { DispatchActionInterface } from './interfaces'
import ROUTER from './router'
import { ROUTES } from './router/routes'
import { RootState, useAppDispatch } from './store'
import { checkLogin } from './store/modules/auth/actions'
import { logout, setUserChatAuthToken, setUserChatId } from './store/modules/auth/reducer'
import { authorizeChat } from './store/modules/chat/actions'

export const RootRouter = () => {
  const { tokenChecked, isAuthorized, roleSet } = useSelector((state: RootState) => state.auth)
  const dispatch = useAppDispatch()
  useTranslation(['apiMessages'])

  useEffect(() => {
    checkAuthLogin()
  }, [dispatch])

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') initTagManager()
  }, [])

  const initTagManager = () => {
    TagManager.initialize({
      gtmId: 'GTM-MF25B5N',
    })
  }

  const checkAuthLogin = () => {
    return dispatch(checkLogin()).then((action: DispatchActionInterface) => {
      if (action.payload?.status === 'ok') {
        dispatch(authorizeChat()).then((action: DispatchActionInterface) => {
          if (action.payload?.status === 'ok') {
            dispatch(setUserChatAuthToken(action.payload?.data.auth_token))
            dispatch(setUserChatId(action.payload?.data.user_id))
          }
        })
      } else {
        dispatch(logout())
      }
    })
  }

  const excludedRoutes = [ROUTES.TELEREHABILITATION_PATIENT_EXERCISE_UPLOAD]

  const isExcluded = (path: string) => {
    return excludedRoutes.includes(path)
  }

  return (
    <>
      {process.env.NODE_ENV === 'production' && (
        <Helmet
          script={[
            {
              type: 'text/javascript',
              innerHTML: `(function(h,o,t,j,a,r){
                              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                              h._hjSettings={hjid:2503711,hjsv:6};
                              a=o.getElementsByTagName('head')[0];
                              r=o.createElement('script');r.async=1;
                              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                              a.appendChild(r);
                            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,
            },
          ]}
        />
      )}

      <BrowserRouter>
        {tokenChecked && (
          <Switch>
            {ROUTER.map(i => {
              return i.private ? (
                <PrivateRoute
                  key={i.title}
                  title={i.title}
                  path={i.path}
                  component={i.component}
                  exact={i.exact}
                  role={i.role}
                />
              ) : (
                <Route
                  key={i.title}
                  path={i.path}
                  render={(routeProps: any) =>
                    isAuthorized ? (
                      isExcluded(i.path) ? (
                        <PageWithTitle component={i.component} title={i.title} {...routeProps} />
                      ) : (
                        roleSet && <Redirect to={ROUTES.DASHBOARD} />
                      )
                    ) : (
                      <PageWithTitle component={i.component} title={i.title} {...routeProps} />
                    )
                  }
                  exact={i.exact}
                />
              )
            })}

            <Route
              path="/"
              exact={true}
              render={() => <Redirect to={isAuthorized ? ROUTES.DASHBOARD : ROUTES.LOGIN} />}
            />

            <Redirect to={isAuthorized ? (roleSet ? ROUTES.ERROR_404 : ROUTES.ROLE_SELECT) : ROUTES.LOGIN} />
          </Switch>
        )}
      </BrowserRouter>
    </>
  )
}

export default RootRouter
